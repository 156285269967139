import React from 'react'
import Parser from 'html-react-parser';
import loadingGif from '../assets/images/loading.gif'
import demoImage from '../assets/images/demo_installatie.png'
import UserPhotoUpload from './UserPhotoUpload'

const plurallabels = {
	'Omvormer': 'Inversores',
	'Zonnepaneel': 'Paneles solares',
	'Laadpaal': 'Puntos de carga',
	'Batterij': 'Baterías'
}

class InstallatieDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			installatie_selected: 1,
			view_selected: 'overview',
			error: null
		};

	}

	toggleView = () => {

		let newview = (this.state.view_selected === 'overview') ? 'detail' : 'overview';

		this.setState({
			view_selected: newview
		});
	}

	navigate(change) {
		if (change === 0) {
			this.setState({
				view_selected: 'overview',
			});
		}
		this.setState({
			installatie_selected: this.state.installatie_selected + change,
		});
	}

	render() {
		const {isLoading} = this.props;
		let navigation, buttonprevious, buttonnext;
		let producten, product;
		var number;

		if (isLoading) {
			return <LoadingDiv />;
		}

		let installaties = this.props.InstallatieData;

		if (!installaties) {
			return (
				<div>
					<h1>Bienvenido/a de nuevo, {this.props.fullname}</h1>
					<p>No se han encontrado instalaciones en esta cuenta. Esto puede ser por el uso de una nueva cuenta que no está actualizada en el sistema.</p>
					<p>¿Debería haber instalaciones? <a href="https://solarcircle.es/contacto/">Contacta con nostros</a>.</p>
				</div>
			);
		} else {

			number = Object.values(installaties).length;

			if (this.state.installatie_selected > 1) {
				buttonprevious = <button className="button left" onClick={() => this.navigate(-1)}>&lt;</button>
			}

			if (this.state.installatie_selected < number) {
				buttonnext = <button className="button right" onClick={() => this.navigate(1)}>&gt;</button>
			}

			let data = this.props.InstallatieData;

			producten = Object.keys(data).map(function(key) {
				return 	Object.keys(data[key]).map(function(productkey) {
					return (data[key][productkey][0]['productcategory'] === 'Omvormer'
						|| data[key][productkey][0]['productcategory'] === 'Zonnepaneel'
						|| data[key][productkey][0]['productcategory'] === 'Laadpaal'
						|| data[key][productkey][0]['productcategory'] === 'Batterij'
					) ?
						data[key][productkey]
						: false;
				});
			});

			product = producten[this.state.installatie_selected-1];

			if (Array.isArray(product)) {
				product = product.filter(
					function (value) {
						return value
					}
				)

				var description = '';
				const descriptionData = [];
				var installatiegarantie_datum = '';	
				for (var i = 0; i < product.length; i++) {
					for (var e = 0; e < product[i].length; e++) {
						let productQty = product[i][e]['qty'];
						let productCategory = product[i][e]['productcategory'];
						let label = productQty === 1 ? productCategory : plurallabels[productCategory];

						const existingItem = descriptionData.find(item => item.key === productCategory);
						if (existingItem) {
							existingItem.qty += productQty;
						} else {
							descriptionData.push({ key: productCategory, label: label.toLowerCase(), qty:product[i][e]['qty'], installation_guarantee_date:product[i][e]['installation_guarantee_date'] });
						}
					}
				}
				Object.keys(descriptionData).map(function (key) {
					installatiegarantie_datum = descriptionData[key]['installation_guarantee_date'].toLowerCase();
					return description += descriptionData[key]['qty'] + ' ' + descriptionData[key]['label'].toLowerCase() + '<br />';
				});
				description = description.slice(0, -6)
			} else {
				description = (
					`<>
						<h1>Bienvenido/a de nuevo, {this.props.fullname}</h1>
						<p>No hay instalaciones conectadas (todavía) en esta cuenta.</p>
					</>`
				)
			}

			var faq = Object.keys(data).map(function(key) {
				return 	Object.keys(data[key]).map(function(productkey) {
					return (productkey === 'faq') ?
						data[key]['faq']

						: false;
				});
			});

			if (Array.isArray(faq[this.state.installatie_selected-1])) {

				faq = faq[this.state.installatie_selected-1].filter(

					function (value) {
						return value
					}
				)
			} else {
				faq = '';
			}

			var docs = Object.keys(data).map(function(key) {
				return 	Object.keys(data[key]).map(function(productkey) {
					return (productkey === 'documents') ?
						data[key]['documents']

						: false;
				});
			});

			if (Array.isArray(docs[this.state.installatie_selected-1])) {

				docs = docs[this.state.installatie_selected-1].filter(

					function (value) {
						return value
					}
				)
			} else {
				docs = '';
			}
			var installatiegarantie = <div className="installatie">
				<h2>Garantía de instalación</h2>
				Garantía de instalación hasta: <strong>{installatiegarantie_datum}</strong>
			</div>

			navigation = (
				<div className="navigation">
					<div className="left">{buttonprevious}&nbsp;</div>
					<div className="label">
						<h3 className="name font-bold">Instalación {this.state.installatie_selected} :</h3>
						<h3 className="detail font-bold">{Parser(description)}</h3>
					</div>
					<div className="right">&nbsp;{buttonnext}</div>
					<div className="clear"></div>

				</div>
			)
		}

		if (this.state.view_selected === 'detail') {

			return (
				<div>
					<h1>Bienvenido/a de nuevo, {this.props.fullname}</h1>

					{navigation}

					{
						Object.keys(product).map(function(key) {
							return 	Object.keys(product[key]).map(function(productkey) {

								return <div className="installatie" key={product[key][productkey]['id']}>
									<h2>{(product[key][productkey]['qty'] === 1) ? product[key][productkey]['productcategory']: plurallabels[product[key][productkey]['productcategory']]}</h2>
									{product[key][productkey]['qty']} X {product[key][productkey]['productname']} <br />
									Garantía del producto hasta: <strong> { (parseFloat(product[key][productkey]['garantee_date'].slice(-4)) < 2000) ? 'niet beschikbaar' : product[key][productkey]['garantee_date']  }</strong> <br />
								</div>
							}) })

					}
					{installatiegarantie}

					<button className="button" onClick={() => this.navigate(0)}>Visión general</button>
				</div>
			);

		} else {

			if (number < 1) {
				return (
					<>
						<h1>Bienvenido/a de nuevo, {this.props.fullname}</h1>
						<p>No hay instalaciones conectadas (todavía) en esta cuenta.</p>
					</>
				);
			}
			let installation_id = Object.keys(this.props.InstallatieData)[this.state.installatie_selected-1]
			let selectedInstallation = this.props.InstallatieData[installation_id]

			let userImage = (selectedInstallation.user_photo) ?  selectedInstallation.user_photo :  demoImage;
			const divStyle = {
				backgroundImage: 'url("' + userImage + '")',
				backgroundSize: 'cover',
				backgroundPosition: 'center'
			};

			return (
				<div>
					<div className="image">
						<div id="installatie_img" style={divStyle}></div>
						<UserPhotoUpload
							installation={installation_id}
							isLoading={this.state.isLoading}
							setCache = {this.props.setCache}
							getCache = {this.props.getCache}
							clearCache = {this.props.clearCache}
							fetchInstallaties = {this.props.fetchInstallaties}
							setLoading = {this.props.setLoading}
						/>
					</div>
					<h1>Bienvenido/a de nuevo, {this.props.fullname}</h1>

					<h3 className="title">Tienes {number} {(number > 1) ? 'instalaciones' : 'instalación' } de Solar Circle.</h3>

					{navigation}
					<ul className="hidedisc">
						<li><button className='button2' to='/installaties' onClick={this.toggleView}>Datos del sistema y - garantía</button></li>
					</ul>

				</div>
			);
		}
	}
}

class LoadingDiv extends React.Component {
	render() {
		return (
			<>
				<h1>Bienvenido/a de nuevo, {this.props.fullname}</h1>
				<div className="LoadingDiv">
					<img src={loadingGif} alt="Loading" />
				</div>
			</>
		)
	}
}

export default InstallatieDetail
