import React from 'react'
import './Chart.css';
import loadingGif from '../assets/images/loading.gif'
import {VictoryBar, VictoryChart, VictoryLine, VictoryTooltip, VictoryContainer, VictoryVoronoiContainer} from 'victory'


const precacheCalls = [
//	'&timeframe=day&period=-1',
//	'&timeframe=month&period=0',
//	'&timeframe=total&period=0'
];

const kwhPriceDefault = 0.18;

const errors = {
    '': 'Lamentablemente, no podemos recuperar tus datos en este momento. Contacta con info@solarcircle.es para que podamos ayudarte.',
    'Unknown-error': 'Lamentablemente, no podemos recuperar tus datos en este momento. Contacta con info@solarcircle.es para que podamos ayudarte.',
    'Pending': 'Para recuperar tus datos, Solar Circle necesita tu consentimiento. Hemos enviado un correo electrónico a --email-- para dar tu consentimiento.',
    'Revoked': 'Para recuperar tus datos, Solar Circle necesita tu consentimiento. Anteriormente diste permiso, pero parece que te lo han retirado. Hemos enviado un correo electrónico a --email-- para dar tu consentimiento de nuevo.',
    'Rejected': 'Para recuperar tus datos, Solar Circle necesita tu consentimiento. Te enviamos un correo electrónico para que dieras tu consentimiento, pero fue rechazado. Hemos enviado un correo electrónico a --email-- para dar tu consentimiento de nuevo.',
    'No-data': 'Lamentablemente, no podemos recuperar tus datos en este momento. Contacta con info@solarcircle.es para que podamos ayudarte.',
    'no-privileges': 'Para recuperar tus datos, Solar Circle necesita tu consentimiento. Lamentablemente, parece que la dirección de correo electrónico en nuestro sistema (--email--) no está configurada como propietaria del sistema en SMA. Contacta con info@solarcircle.es para que podamos ayudarte.',
    'Not enough userdata to connect': 'Todavía no hay ninguna instalación asociada a esta cuenta, probablemente porque se trata de una instalación nueva. Si crees que esta instalación ya debería estar registrada, contacta con info@solarcircle.es.',
    'Not Found': 'Para recuperar tus datos, Solar Circle necesita tu consentimiento. Estamos intentando enviarte un correo electrónico de autorización, pero parece que tu cuenta aún no está correctamente vinculada en EnnexOS. <br /><br />Puedes resolverlo accediendo a la dirección que figura a continuación: <br /><br /> <a href="https://ennexos.sunnyportal.com/login">https://ennexos.sunnyportal.com/login</a>.<br /><br />Una vez hecho esto, puedes actualizar esta pantalla para enviar el correo electrónico de autorización.'
};

class MonthlyChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: false,
            timeframe: 'day',
            period: 0,
            cache: [],
            prices: {},
            summary: [],
            cached: null,
            url: this.getUrl() + '&timeframe=day&period=0',
            isCached: false,
            preCacheLoaded: false,
            isActiveDay: true,
            isActiveMonth: false,
            totalYield: 0,
            totalYieldEuro: 0,
            currentYield: 0,
            currentYieldLabel: 'Hoy',
            currentChartLabel: 'Hoy'
        };

        // This binding is necessary to make `this` work in the callback
        this.deductPeriod = this.deductPeriod.bind(this);
        this.addPeriod = this.addPeriod.bind(this);

    }

    preloadCache() {

        let url = this.getUrl();

        precacheCalls.map((item) =>
            fetch(url + item)
                .then(response => response.json())
                .then(data => this.setState({
                    cache: [...this.state.cache, {'url': url + item, 'response': data.Result.usage}]
                }))
        );

    }

    updateChart() {
        var cachedResponse = this.props.getCache(this.state.url);
        if (cachedResponse) {
            this.setState({
                data: cachedResponse.yield,
                summary: this.updateSummary(cachedResponse),

            });
        } else {
            this.fetchUrl(this.state.url);
        }
        this.fetchYearTotal();
        this.fetchTotal();
    }

    getUrl = () => {

        let username = localStorage.getItem('user_name')
        let token = localStorage.getItem('token')

        let url = process.env.REACT_APP_API_URL + '?function=getYield&user=' + username + '&token=' + token;

        return url;
    }

    fetchUrl(url) {

        this.setState({
            data: [],
            isLoading: true
        });

        fetch(url)
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Response from API not ok')
                }
            })
            .then((data) => {
                if (data.Error) {
                    this.setState({
                        error: data.Error,
                        emailsent: (data.Sent) ? data.Sent : 'Onbekend',
                        cache: false,
                        isLoading: false
                    })
                } else if (!data.Result) {
                    this.setState({
                        error: 'No-data',
                        cache: false,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        data: (data.Result.yield === null) ? [] : data.Result.yield,
                        cache: {'url': url, 'response': data.Result},
                        summary: this.updateSummary(data.Result),
                        prices: data.Result.prices,
                        isLoading: false
                    })
                }
            })
            .then((data) => {
                if (this.state.cache) {
                    this.props.setCache(this.state.cache, this.state.timeframe, null)
                }
            }).catch((error) => {
                this.setState({
                    isLoading: false
                });
            });

    }

    fetchTotal() {

        this.setState({isLoading: true});
        let url = this.getUrl() + `&timeframe=total`;

        var cachedResponse = this.props.getCache(url);

        if (cachedResponse) {
            this.setState({
                prices: cachedResponse.prices,
                totalYield: this.sumTotals(cachedResponse.yield),
                totalYieldEuro: this.sumTotals(cachedResponse.yield, cachedResponse.prices),
                isLoading: false
            });
        } else {
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    if (data.Result) {
                        this.setState({
                            cache: {'url': url, 'response': data.Result},
                            prices: data.Result.prices,
                            totalYield: this.sumTotals(data.Result.yield),
                            totalYieldEuro: this.sumTotals(data.Result.yield, data.Result.prices),
                            isLoading: false
                        })
                    }
                })
                .then((data) => {
                    if (this.state.cache) {
                        this.props.setCache(this.state.cache, 'total', null)
                    }
                });

        }

    }

    fetchYearTotal() {

        this.setState({isLoading: true});
        let url = this.getUrl() + `&timeframe=year&period=0`;

        var cachedResponse = this.props.getCache(url);

        if (cachedResponse) {
            this.setState({
                prices: cachedResponse.prices,
                totalYearYield: this.sumTotals(cachedResponse.yield),
                totalYearYieldEuro: this.sumTotals(cachedResponse.yield, cachedResponse.prices, 2022)
            });
        } else {
            fetch(url)
                .then(response => response.json())
                .then(data => this.setState({
                    cache: {'url': url, 'response': data.Result},
                    prices: data.Result.prices,
                    totalYearYield: this.sumTotals(data.Result.yield),
                    totalYearYieldEuro: this.sumTotals(data.Result.yield, data.Result.prices, 2022)
                }))
                .then((data) => {
                    if (this.state.cache) {
                        this.props.setCache(this.state.cache, 'year', null)
                    }
                });

        }

    }

    sumTotals(kwh, prices = null, year = null) {
        let sum = 0;
        let array = kwh;

        if (prices) {
            if (year) {
                if (typeof array === "object" && (array !== null)) {
                    Object.keys(array).map((row) => (
                        sum += parseFloat(array[row][1]) * this.getKwhPrice(prices, parseFloat(year))
                    ));
                }
            } else {
                if (typeof array === "object" && (array !== null)) {
                    Object.keys(array).map((row) => (
                        sum += parseFloat(array[row][1]) * this.getKwhPrice(prices, parseFloat(array[row][0]))
                    ));
                }
            }
        } else {
            if (typeof array === "object" && (array !== null)) {
                Object.keys(array).map((row) => (
                    sum += parseFloat(array[row][1])
                ));
            }
        }

        return sum;
    }

    getKwhPrice = (prices = null, year = 0) => {
        let handle = 'kwh_price_' + year;
        let kwhPrice = 0;

        if (handle in prices) {
            kwhPrice = prices[handle];
        } else if ('kwh_price' in prices) {
            kwhPrice = prices['kwh_price'];
        } else {
            kwhPrice = kwhPriceDefault;
        }

        return kwhPrice;
    }

    updateSummary(response) {
        let currentYieldLabel = '';

        if (this.state.timeframe === 'day') {
            if (this.state.period === 0) {
                currentYieldLabel = 'Hoy';

            } else if (this.state.period === -1) {
                currentYieldLabel = 'Ayer';
            } else {
                const today = new Date();
                today.setDate(today.getDate() + this.state.period);
                currentYieldLabel = today.toLocaleDateString();
            }

            this.setState({
                //if there is more than 500kWh generated, we assume that zeversolar is sending us watthour
                currentYield: (this.sumTotals(response.yield) > 500) ? (this.sumTotals(response.yield) / 1000) : this.sumTotals(response.yield),
                currentYieldUnit: 'kWh',
                currentYieldLabel: currentYieldLabel,
                currentChartLabel: currentYieldLabel
            });
        }

        if (this.state.timeframe === 'month') {
            if (this.state.period === 0) {
                currentYieldLabel = 'Este mes';

            } else if (this.state.period < 0) {
                const today = new Date();
                today.setMonth(today.getMonth() + (this.state.period))
                currentYieldLabel = today.toLocaleDateString('nl-NL', {month: 'long'});
                currentYieldLabel = currentYieldLabel.charAt(0).toUpperCase() + currentYieldLabel.slice(1)
            }

            this.setState({
                currentYield: this.sumTotals(response.yield),
                currentYieldUnit: 'kWh',
                currentYieldLabel: currentYieldLabel,
                currentChartLabel: currentYieldLabel
            });
        }

        if ((this.state.timeframe === 'year') && (this.state.period < 0)) {
            const today = new Date();
            today.setDate(today.getDate() + (this.state.period * 365));
            currentYieldLabel = today.toLocaleDateString('nl-NL', {year: 'numeric'});

            this.setState({
                currentYield: this.sumTotals(response.yield),
                currentYieldUnit: 'kWh',
                currentYieldLabel: currentYieldLabel
            });
        }

        if (this.state.timeframe === 'year') {

            const today = new Date();
            today.setDate(today.getDate() + (this.state.period * 365));

            this.setState({
                currentChartLabel: today.toLocaleDateString('nl-NL', {year: 'numeric'})
            });
        }

        if (this.state.timeframe === 'total') {
            this.setState({
                currentChartLabel: 'Total'
            });
        }

    }

    componentDidMount() {
        this.preloadCache();
        this.updateChart();
    }

    changeTimeframe = (timeframe) => {

        let url = this.getUrl();

        this.setState({
            period: 0,
            timeframe: timeframe,
            url: url + "&timeframe=" + timeframe + "&period=0"
        }, () => {
            this.updateChart();
        });
    }

    deductPeriod() {

        let url = this.getUrl();

        this.setState({
            period: this.state.period - 1,
            url: url + "&timeframe=" + this.state.timeframe + "&period=" + (this.state.period - 1)
        }, () => {
            this.updateChart();
        });
    }

    addPeriod() {

        let url = this.getUrl();

        this.setState({
            period: this.state.period + 1,
            url: url + "&timeframe=" + this.state.timeframe + "&period=" + (this.state.period + 1)
        }, () => {
            this.updateChart();
        });
    }

    checkChartExists = (i, chart) => {
        let found = false;
        chart.forEach(
            row => {
                if (row.x === i) {
                    found = true;
                }
            }
        );
        return found;
    }


    render() {
        let chartresult = '';
        let summary = '';
        let timeframenavigation = '';
        let periodnavigation = '';
        const {data, isLoading} = this.state;

        if (isLoading) {
            return <LoadingDiv/>;
        }

        if (data) {

            let theDay, theValue, x, y, label, currentDate
            let chartData = []
            let buttonnext, buttonprevious;
            let dates = [];

            if (data.length > 0) {

                data.forEach((day, index) => {

                    // day[0] = day[0] === "ene" ? 0 : day[0]
                    // day[0] = day[0] === "feb" ? 1 : day[0]
                    // day[0] = day[0] === "mar" ? 2 : day[0]
                    // day[0] = day[0] === "avr" ? 3 : day[0]
                    // day[0] = day[0] === "may" ? 4 : day[0]
                    // day[0] = day[0] === "jun" ? 5 : day[0]
                    // day[0] = day[0] === "jul" ? 6 : day[0]
                    // day[0] = day[0] === "ago" ? 7 : day[0]
                    // day[0] = day[0] === "set" ? 8 : day[0]
                    // day[0] = day[0] === "oct" ? 9 : day[0]
                    // day[0] = day[0] === "nov" ? 10 : day[0]
                    // day[0] = day[0] === "dic" ? 11 : day[0]

                    theDay = parseInt(day[0])
                    theValue = parseFloat(day[1])

                    var d = new Date();

                    if (this.state.timeframe === "day" || this.state.timeframe == null) {
                        currentDate = new Date(d.getFullYear(), d.getMonth(), d.getDate() + this.state.period, theDay, 0, 0)
                        x = currentDate.getHours();
                        y = theValue;
                        label = currentDate.getHours() + ' uur: ' + theValue.toFixed(2) + ' kW';
                    }

                    if (this.state.timeframe === "month") {
                        let mnth = d.getMonth()
                        if (this.state.period) {
                            mnth += this.state.period * 1;
                        }

                        let day = index + 1
                        currentDate = new Date(d.getFullYear(), mnth, day, 0, 0, 0);
                        x = currentDate.getDate();
                        y = theValue;
                        label = currentDate.getDate() + ' ' + currentDate.toLocaleString('default', {month: 'short'}) + ': ' + theValue.toFixed(2) + ' kWh';
                    }

                    if (this.state.timeframe === "year") {
                        let year = d.getFullYear()
                        if (this.state.period) {
                            year += this.state.period * 1;
                        }

                        let mnth = index;
                        currentDate = new Date(year, mnth, 1, 0, 0, 0)
                        x = currentDate.getMonth() + 1;
                        let monthname = currentDate.toLocaleString('es-ES', {month: 'short'});
                        x = monthname.charAt(0).toUpperCase() + monthname.substr(1, 2);
                        y = theValue;
                        label = x + ': ' + theValue.toFixed(2) + ' kWh';
                    }

                    if (this.state.timeframe === "total") {
                        currentDate = new Date(theDay, 1, 1, 0, 0, 0);
                        x = currentDate.getFullYear().toString();
                        y = theValue;
                        label = currentDate.getFullYear().toString() + ': ' + theValue.toFixed(0) + ' kWh';
                    }

                    dates.push(theDay)

                    chartData.push({x: x, y: y, label: label, eventKey: Math.random()});

                });


                var i = 1;
                var d = new Date();
                var chartMax = {
                    "day": 24,
                    "month": new Date(d.getFullYear(), d.getMonth() + this.state.period, 0).getDate(),
                    "year": 12,
                    "total": data.length
                };

                if (this.state.timeframe !== "total") {

                    for (i; i < chartMax[this.state.timeframe]; i++) {
                        if (!this.checkChartExists(i, chartData)) {
                            chartData.push({x: (i), y: null, label: null, eventKey: Math.random()});
                        }
                    }

                }


                if (this.state.timeframe === 'day') {
                    chartresult = (
                        <VictoryChart

                            containerComponent={

                                <VictoryVoronoiContainer
                                    style={{
                                        touchAction: "auto"
                                    }}
                                    labelComponent={
                                        <VictoryTooltip dy={-7} constrainToVisibleArea/>
                                    }
                                />


                            }
                            domainPadding={{x: 25}}
                        >
                            <VictoryLine
                                style={{
                                    data: {
                                        stroke: "#225db3",
                                        strokeWidth: 5,
                                        strokeLinecap: "round"
                                    }
                                }}
                                data={chartData}
                                labelComponent={<VictoryTooltip
                                    style={{
                                        fontSize: "12px",
                                        fontFamily: "Gotham Light"
                                    }}
                                    flyoutPadding={{
                                        top: 5, bottom: 5, left: 10, right: 10
                                    }}
                                    flyoutStyle={{
                                        stroke: "#225db3",
                                        fill: "#fff"
                                    }}
                                />}
                            />
                        </VictoryChart>
                    )

                } else {

                    var barWidths = {
                        "month": 8,
                        "year": 15,
                        "total": 20
                    }

                    chartresult = (
                        <VictoryChart
                            containerComponent={
                                <VictoryContainer
                                    style={{
                                        touchAction: "auto"
                                    }}
                                />
                            }
                            domainPadding={{x: 25}}
                        >
                            <VictoryBar
                                barWidth={barWidths[this.state.timeframe]}
                                style={{data: {fill: "#225db3"}}}
                                data={chartData}
                                labelComponent={<VictoryTooltip
                                    style={{
                                        fontSize: "12px",
                                        fontFamily: "Gotham Light"
                                    }}
                                    flyoutPadding={{
                                        top: 5, bottom: 5, left: 10, right: 10
                                    }}
                                    flyoutStyle={{
                                        stroke: "#225db3",
                                        fill: "#fff"
                                    }}
                                />}
                            />
                        </VictoryChart>
                    )
                }
            } else {
                chartresult = <span
                    dangerouslySetInnerHTML={{__html: 'Lamentablemente, este gráfico no puede mostrarse. <br /><br />Por favor, visites <a href="https://solarcircle.es/detalles-de-problemas-tecnicos/" target="_blank">https://solarcircle.es/detalles-de-problemas-tecnicos/</a> si esperabas datos aquí.'}}/>;
            }

            summary = (
                <div className="summary">
                    <ul>
                        <li>
                            <label>{this.state.currentYieldLabel}</label>
                            {this.state.currentYield > 0 ? Math.round(this.state.currentYield) : '-'} {this.state.currentYieldUnit}
                        </li>
                        <li>
                            <label>Este año</label>
                            {this.state.totalYearYield > 0 ? Math.round(this.state.totalYearYield) : '-'} kWh / €{this.state.totalYearYieldEuro > 0 ? Math.round(this.state.totalYearYieldEuro) : '-'},-
                        </li>
                        <li>
                            <label>Total</label>
                            {this.state.totalYield > 0 ? Math.round(this.state.totalYield) : '-'} kWh / €{this.state.totalYieldEuro > 0 ? Math.round(this.state.totalYieldEuro) : '-'},-
                        </li>
                    </ul>
                </div>
            )

            timeframenavigation = (
                <div className="timeframenavigation">
                    <ul>
                        <li>
                            <button className={` ${(this.state.timeframe === "day") ? "active" : ""}`}
                                    onClick={() => this.changeTimeframe('day')}>DÍA
                            </button>
                        </li>
                        <li>
                            <button className={` ${(this.state.timeframe === "month") ? "active" : ""}`}
                                    onClick={() => this.changeTimeframe('month')}>MES
                            </button>
                        </li>
                        <li>
                            <button className={` ${(this.state.timeframe === "year") ? "active" : ""}`}
                                    onClick={() => this.changeTimeframe('year')}>AÑO
                            </button>
                        </li>
                        <li>
                            <button className={` ${(this.state.timeframe === "total") ? "active" : ""}`}
                                    onClick={() => this.changeTimeframe('total')}>TOTAL
                            </button>
                        </li>
                    </ul>
                </div>
            )

            if (this.state.period < 0) {
                buttonnext = <button className='button right' onClick={this.addPeriod}>&gt;</button>
            }

            if (this.state.timeframe !== 'total' && !(this.props.api_type === 'sma' && this.state.timeframe === 'month' && this.state.period > 0)) {
                buttonprevious = <button className='button left' onClick={this.deductPeriod}>&lt;</button>
            }

            periodnavigation = (
                <div className="navigation">
                    <div className="left">
                        {buttonprevious}&nbsp;
                    </div>
                    <div className="label">
                        {this.state.currentChartLabel}
                    </div>
                    <div className="right">
                        &nbsp;{buttonnext}
                    </div>
                </div>
            )

        }

        let content = '';


        if (this.state.error && this.state.error in errors) {
            content = (
                <div className="chartDiv">
                    {!(this.state.error in errors) ?
                        'Se ha producido un error desconocido, contacta con info@solarcircle.es si el problema persiste. (' + this.state.error + ')'
                        :
                        <span
                            dangerouslySetInnerHTML={{__html: errors[this.state.error].replace(/--email--/g, this.state.emailsent)}}/>
                    }
                </div>
            )
        } else {
            content = (
                <div className="chartDiv">
                    {summary}
                    {timeframenavigation}
                    {periodnavigation}
                    {chartresult}
                </div>
            )

        }

        return (
            <div>
                {content}
            </div>
        )
    }
}

class LoadingDiv extends React.Component {
    render() {
        return (
            <div className="LoadingDiv">
                <img src={loadingGif} alt="Cargando"/>
            </div>
        )
    }
}

class Chart extends React.Component {
    render() {
        return (
            <div>
                <MonthlyChart
                    setCache={this.props.setCache}
                    getCache={this.props.getCache}
                    api_type={this.props.api_type}
                    className="usageChart"
                />
            </div>
        )
    }
}

export default Chart
